import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LegalContent from "../components/legal/legalContent"

export const PrivacyPageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: { slug, title, description, copy },
    },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} description={description} pathname={slug} />
      <LegalContent copy={copy} />
    </Layout>
  )
}

export default PrivacyPageTemplate

export const pageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-page" } }) {
      frontmatter {
        slug
        title
        description
        copy
      }
    }
  }
`
